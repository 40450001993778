import { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AllStyledComponent } from '@remirror/styles/emotion';

import { IonicTheme } from './IonicTheme';

export const AppThemeProvider = ({ children }) => {
  useEffect(() => {
    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setStyle({ style: Style.Light });
    }
  }, []);

  return (
    <AllStyledComponent>
      <IonicTheme />
      {children}
    </AllStyledComponent>
  );
};
