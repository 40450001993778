import { css } from '@emotion/react';
import { Slide, SlideProps } from '@mui/material';
import { appWithTheme } from '@scorenco/components';
import {
  ApolloService,
  AmplifyService,
  SentryService,
  CrispService,
  useTranslation,
} from '@scorenco/core';
import { AppLayout, NextI18nService, AppPropsWithLayout } from '@scorenco/next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import i18nextHttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';

import { ErrorFallback } from '../components/ErrorFallback';
import { AppThemeProvider } from '../style';
import '../style/vendor.css';
import { T_KEYS } from '../translations';

if (
  process.env.NEXT_PUBLIC_TARGET_PLATFORM === 'ios' ||
  process.env.NEXT_PUBLIC_TARGET_PLATFORM === 'android'
) {
  AmplifyService.init({
    ssr: false,
  });
} else {
  AmplifyService.init();
}
CrispService.init();


NextI18nService.init({
  i18n: {
    defaultLocale: 'fr',
    locales: ['fr', 'de'],
  },
  ns: ['snc-components', 'snc-core', 'common'],
});

const StyledStyle = (props: SlideProps) => (
  <div
    css={(theme) => css`
      .notistack-MuiContent-success {
        background-color: ${theme.vars.palette.success.main};
        color: ${theme.vars.palette.success.contrastText};
      }
      .notistack-MuiContent-info {
        background-color: ${theme.vars.palette.info.main};
        color: ${theme.vars.palette.info.contrastText};
      }
      .notistack-MuiContent-error {
        background-color: ${theme.vars.palette.error.main};
        color: ${theme.vars.palette.error.contrastText};
      }
      .notistack-MuiContent-warning {
        background-color: ${theme.vars.palette.warning.main};
        color: ${theme.vars.palette.warning.contrastText};
      }
    `}
  >
    <Slide {...props} />
  </div>
);

const App = (appProps: AppPropsWithLayout) => {
  const { t } = useTranslation();

  return (
    <CrispService hideOnStart>
      <AppThemeProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          TransitionComponent={StyledStyle}
        >
          <SentryService
            Fallback={(props) => {
              return <ErrorFallback {...props} />;
            }}
          >
            <ApolloService>
              <AmplifyService>
                <Head>
                  <title>{t(T_KEYS.DEFAULT_HTML_TITLE)}</title>
                </Head>
                <AppLayout {...appProps} />
              </AmplifyService>
            </ApolloService>
          </SentryService>
        </SnackbarProvider>
      </AppThemeProvider>
    </CrispService>
  );
};

if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_TARGET_PLATFORM === 'web'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const pwaLoader = require('@ionic/pwa-elements/loader');
  pwaLoader.defineCustomElements(window);
}

export default appWithTheme(NextI18nService.appEnhancer(App));
