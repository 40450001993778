import { css, useTheme } from '@emotion/react';
import { Global } from '@emotion/react';
import { vars } from '@scorenco/components';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

export const IonicTheme = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        /* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
        *,
        ::before,
        ::after {
          box-sizing: border-box;
        }

        /** Ionic CSS Variables **/
        :root {
          --safe-area-top: env(safe-area-inset-top);
          --safe-area-bottom: env(safe-area-inset-bottom, 0);
          --safe-area-left: env(safe-area-inset-left, 0);
          --safe-area-right: env(safe-area-inset-right, 0);
          --app-bar-height: calc(var(--ion-statusbar-padding, 0px) + 4.1rem);

          --ion-background-color: ${theme.vars.palette.background.default};
          --ion-border-color: ${theme.vars.palette.divider};
          --ion-text-color: ${theme.vars.palette.text.primary};

          --ion-toolbar-background: ${theme.vars.palette.background.paper};
          --ion-toolbar-color: ${theme.vars.palette.text.primary};

          --ion-item-background: ${theme.vars.palette.background.paper};

          --background-activated: ${theme.vars.palette.text.primary};
        }

        /* stylelint-disable-next-line selector-type-no-unknown */
        ion-list {
          --ion-item-background: ${theme.vars.palette.background.default};

          &.list-ios,
          &.list-md {
            padding-top: 1rem;
            padding-bottom: 1rem;

            & + & {
              padding-top: 0;
            }
          }
        }

        /* stylelint-disable-next-line selector-type-no-unknown */
        ion-item {
          --ion-item-background: ${theme.vars.palette.background.paper};
          --inner-padding-start: 1rem;
          --background-activated: var(${vars.color.secondary500});
          --padding-start: 0;
          border-top: 1px solid ${theme.vars.palette.divider};

          & + & {
            border-top: none;
          }
        }

        /* stylelint-disable-next-line selector-type-no-unknown */
        ion-segment {
          &.ios {
            --background: ${theme.vars.palette.background.default};
          }
        }

        /* stylelint-disable-next-line selector-type-no-unknown */
        ion-segment-button {
          --color: var(${vars.color.secondary500});
          --color-checked: var(${vars.color.primary500});
          --indicator-color: var(${vars.color.primary500});

          &.ios {
            --indicator-color: ${theme.vars.palette.background.paper};
          }
        }

        /* stylelint-disable-next-line selector-type-no-unknown */
        .header-md {
          box-shadow:
            0 0 2rem 0 #bdbdbd33,
            0 0 2px 0 #bdbdbd22;

          &::after {
            content: none !important;
          }
        }
      `}
    />
  );
};
