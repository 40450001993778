import {
  BaseLayout,
  GetInitialPropsWithPrevious,
  GetServerSidePropsWithPrevious,
  NextI18nService,
  GetLayout,
} from '@scorenco/next';
import { GetStaticProps } from 'next';

import { MobileLayoutComponent } from './MobileLayoutComponent';

type MobileLayoutOptions = {
  i18n?: Parameters<typeof NextI18nService.getInitalPropsFn>['0'];
};

export const MobileLayout = {
  ...BaseLayout,
  buildGetLayout: (getLayouts?: GetLayout[]) => {
    return BaseLayout.buildGetLayout([
      () => <MobileLayoutComponent />,
      ...(getLayouts || []),
    ]);
  },
  buildGetInitialProps: (
    options?: MobileLayoutOptions,
    list?: GetInitialPropsWithPrevious[]
  ) =>
    BaseLayout.buildGetInitialProps([
      NextI18nService.getInitalPropsFn(options?.i18n),
      ...(list || []),
    ]),

  buildGetServerSideProps: (
    options?: MobileLayoutOptions,
    list?: GetServerSidePropsWithPrevious[]
  ) =>
    BaseLayout.buildGetServerSideProps([
      NextI18nService.getServerSidePropsFn(options?.i18n),
      ...(list || []),
    ]),
  buildGetStaticProps: (
    options?: MobileLayoutOptions,
    list?: GetStaticProps[]
  ) =>
    BaseLayout.buildGetStaticProps([
      NextI18nService.getStaticPropsFn(options?.i18n),
      ...(list || []),
    ]),
};
