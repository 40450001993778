import { Translations } from '@scorenco/components';

export const T_KEYS = {
  ...Translations.T_KEYS,

  DEFAULT_HTML_TITLE: 'DEFAULT_HTML_TITLE',
  OR_SEPARATOR: 'OR_SEPARATOR',

  ERROR_TITLE: 'ERROR_TITLE',
  ERROR_BACK: 'ERROR_BACK',

  TAB_BAR_FAVORITES: 'TAB_BAR_FAVORITES',
  TAB_BAR_INFOS: 'TAB_BAR_INFOS',
  TAB_BAR_MATCHS: 'TAB_BAR_MATCHS',
  TAB_BAR_ASSISTANCE: 'TAB_BAR_ASSISTANCE',
  TAB_BAR_TOURNAMENTS: 'TAB_BAR_TOURNAMENTS',
  TAB_BAR_NOTIFICATIONS: 'TAB_BAR_NOTIFICATIONS',
  TAB_BAR_PROFIL: 'TAB_BAR_PROFIL',

  SEARCH_PLACEHOLDER: 'SEARCH_PLACEHOLDER',
  SEARCH_SHOW_MORE: 'SEARCH_SHOW_MORE',
  SEARCH_NOT_FOUND: 'SEARCH_NOT_FOUND',

  AUTHENTIFICATION_SIGNED_OUT_WELCOME_TEXT:
    'AUTHENTIFICATION_SIGNED_OUT_WELCOME_TEXT',
  AUTHENTIFICATION_SIGNED_OUT_DESCRIPTION:
    'AUTHENTIFICATION_SIGNED_OUT_DESCRIPTION',
  AUTHENTIFICATION_FORGOT_PASSWORD_DESCRIPTION:
    'AUTHENTIFICATION_FORGOT_PASSWORD_DESCRIPTION',

  FAVORITES_TEAMS_TITLE: 'FAVORITES_TEAMS_TITLE',
  FAVORITES_TEAMS_HELP_TEXT: 'FAVORITES_TEAMS_HELP_TEXT',
  FAVORITES_TEAMS_QUESTION_1: 'FAVORITES_TEAMS_QUESTION_1',
  FAVORITES_TEAMS_QUESTION_2: 'FAVORITES_TEAMS_QUESTION_2',
  FAVORITES_TEAMS_QUESTION_3: 'FAVORITES_TEAMS_QUESTION_3',
  FAVORITES_TEAMS_QUESTION_4: 'FAVORITES_TEAMS_QUESTION_4',
  FAVORITES_COMPETITIONS_TITLE: 'FAVORITES_COMPETITIONS_TITLE',
  FAVORITES_COMPETITIONS_HELP_TEXT: 'FAVORITES_COMPETITIONS_HELP_TEXT',

  FORCE_UPDATE_TITLE: 'FORCE_UPDATE_TITLE',
  FORCE_UPDATE_DESCRIPTION: 'FORCE_UPDATE_DESCRIPTION',
  FORCE_UPDATE_CTA_LABEL: 'FORCE_UPDATE_CTA_LABEL',
  FORCE_UPDATE_CTA_LINK_ANDROID: 'FORCE_UPDATE_CTA_LINK_ANDROID',
  FORCE_UPDATE_CTA_LINK_IOS: 'FORCE_UPDATE_CTA_LINK_IOS',

  EVENTS_DEMO_CTA: 'EVENTS_DEMO_CTA',
  EVENTS_SHOW_PREVIOUS: 'EVENTS_SHOW_PREVIOUS',
  EVENTS_SHOW_NEXT: 'EVENTS_SHOW_NEXT',
  EVENTS_TODAY_SEPARATOR: 'EVENTS_TODAY_SEPARATOR',
  EVENTS_EMPTY: 'EVENTS_EMPTY',
  EVENTS_EMPTY_FAVORITES: 'EVENTS_EMPTY_FAVORITES',
  EVENT_INFO_SLIDE_TITLE: 'EVENT_INFO_SLIDE_TITLE',
  CLUB_INFOS_PRESENTATION: 'CLUB_INFOS_PRESENTATION',
  CLUB_INFOS_LINKS: 'CLUB_INFOS_LINKS',
  CLUB_INFOS_WEBSITE: 'CLUB_INFOS_WEBSITE',
  CLUB_INFOS_TICKETS_LINK: 'CLUB_INFOS_TICKETS_LINK',
  EVENT_INFO_SLIDE_DATE_FORMAT: 'EVENT_INFO_SLIDE_DATE_FORMAT',
  EVENT_INFO_SLIDE_DATE_EMPTY: 'EVENT_INFO_SLIDE_DATE_EMPTY',
  EVENT_INFO_SLIDE_PLACE_EMPTY: 'EVENT_INFO_SLIDE_PLACE_EMPTY',
  EVENT_INFO_SLIDE_SEE_GAMESHEET: 'EVENT_INFO_SLIDE_SEE_GAMESHEET',
  EVENT_INFO_SLIDE_SEE_FEDE_URL: 'EVENT_INFO_SLIDE_SEE_FEDE_URL',
  EVENT_SET_PLACE: 'EVENT_SET_PLACE',

  EVENT_OTHERSEVENTS_SLIDE_TITLE: 'EVENT_OTHERSEVENTS_SLIDE_TITLE',
  EVENT_RANKING_SLIDE_TITLE: 'EVENT_RANKING_SLIDE_TITLE',

  CLUB_TAB_TEAMS: 'CLUB_TAB_TEAMS',
  CLUB_TAB_MATCHS: 'CLUB_TAB_MATCHS',
  CLUB_TAB_INFOS: 'CLUB_TAB_INFOS',

  TEAM_TAB_MATCHS: 'TEAM_TAB_MATCHS',
  TEAM_TAB_RANKING: 'TEAM_TAB_RANKING',
  TEAM_TAB_FOCUS: 'TEAM_TAB_FOCUS',
  TEAM_TAB_INFORMATIONS: 'TEAM_TAB_INFORMATIONS',
  TEAM_ADD_TO_FAVORITES: 'TEAM_ADD_TO_FAVORITES',
  TEAM_NO_EVENT_TODAY: 'TEAM_NO_EVENT_TODAY',

  LIVE_TAB_LIVE_TITLE: 'LIVE_TAB_LIVE_TITLE',
  LIVE_TAB_INFO_TITLE: 'LIVE_TAB_INFO_TITLE',
  LIVE_SHOW_NEXT: 'LIVE_SHOW_NEXT',
  LIVE_EMPTY_BEFORE_1: 'LIVE_EMPTY_BEFORE_1',
  LIVE_EMPTY_BEFORE_2: 'LIVE_EMPTY_BEFORE_2',
  LIVE_EMPTY_BEFORE_3: 'LIVE_EMPTY_BEFORE_3',
  LIVE_EMPTY_BEFORE_4: 'LIVE_EMPTY_BEFORE_4',
  LIVE_EMPTY_AFTER_1: 'LIVE_EMPTY_AFTER_1',
  LIVE_EMPTY_AFTER_2: 'LIVE_EMPTY_AFTER_2',
  LIVE_EMPTY_AFTER_3: 'LIVE_EMPTY_AFTER_3',
  LIVE_EMPTY_AFTER_4: 'LIVE_EMPTY_AFTER_4',
  LIVE_EMPTY_COMMENT_1: 'LIVE_EMPTY_COMMENT_1',
  LIVE_EMPTY_COMMENT_2: 'LIVE_EMPTY_COMMENT_2',

  EVENT_STATS_EDITOR_STOPPED_ACTIONS: 'EVENT_STATS_EDITOR_STOPPED_ACTIONS',
  EVENT_STATS_EDITOR_PLAY_ACTIONS: 'EVENT_STATS_EDITOR_PLAY_ACTIONS',
  EVENT_STATS_EDITOR_SUBSTITUTION_ACTION:
    'EVENT_STATS_EDITOR_SUBSTITUTION_ACTION',
  EVENT_STATS_EDITOR_CARD_ACTION: 'EVENT_STATS_EDITOR_CARD_ACTION',
  EVENT_STATS_EDITOR_FAULT_ACTION: 'EVENT_STATS_EDITOR_FAULT_ACTION',
  EVENT_STATS_EDITOR_OFFSIDE_ACTION: 'EVENT_STATS_EDITOR_OFFSIDE_ACTION',
  EVENT_STATS_EDITOR_CSC_ACTION: 'EVENT_STATS_EDITOR_CSC_ACTION',
  EVENT_STATS_EDITOR_TOUCH_ACTION: 'EVENT_STATS_EDITOR_TOUCH_ACTION',
  EVENT_STATS_EDITOR_CORNER_ACTION: 'EVENT_STATS_EDITOR_CORNER_ACTION',
  EVENT_STATS_EDITOR_FREEKICK_ACTION: 'EVENT_STATS_EDITOR_FREEKICK_ACTION',
  EVENT_STATS_EDITOR_6_METERS_ACTION: 'EVENT_STATS_EDITOR_6_METERS_ACTION',
  EVENT_STATS_EDITOR_PENALTY_ACTION: 'EVENT_STATS_EDITOR_PENALTY_ACTION',
  EVENT_STATS_EDITOR_CENTER_ACTION: 'EVENT_STATS_EDITOR_CENTER_ACTION',
  EVENT_STATS_EDITOR_DRIBBLE_ACTION: 'EVENT_STATS_EDITOR_DRIBBLE_ACTION',
  EVENT_STATS_EDITOR_CLEARANCE_ACTION: 'EVENT_STATS_EDITOR_CLEARANCE_ACTION',
  EVENT_STATS_EDITOR_DUEL_ACTION: 'EVENT_STATS_EDITOR_DUEL_ACTION',
  EVENT_STATS_EDITOR_INTERCEPTION_ACTION:
    'EVENT_STATS_EDITOR_INTERCEPTION_ACTION',
  EVENT_STATS_EDITOR_SHOOT_ACTION: 'EVENT_STATS_EDITOR_SHOOT_ACTION',
  EVENT_STATS_EDITOR_PASS_ACTION: 'EVENT_STATS_EDITOR_PASS_ACTION',
  EVENT_STATS_EDITOR_START_GAME_ACTION: 'EVENT_STATS_EDITOR_START_GAME_ACTION',
  EVENT_STATS_EDITOR_END_GAME_ACTION: 'EVENT_STATS_EDITOR_END_GAME_ACTION',
  EVENT_STATS_EDITOR_START_PERIOD_ACTION:
    'EVENT_STATS_EDITOR_START_PERIOD_ACTION',
  EVENT_STATS_EDITOR_END_PERIOD_ACTION: 'EVENT_STATS_EDITOR_END_PERIOD_ACTION',

  COMPETITION_EVENTS_EMPTY: 'COMPETITION_EVENTS_EMPTY',

  PLAYER_ADD: 'PLAYER_ADD',
  PLAYER_EDIT: 'PLAYER_EDIT',
  PLAYER_NAME: 'PLAYER_NAME',
  PLAYER_NUMBER: 'PLAYER_NUMBER',
  PLAYER_STARTING: 'PLAYER_STARTING',
  PLAYER_CAPTAIN: 'PLAYER_CAPTAIN',
  PLAYER_COMPO_TITLE: 'PLAYER_COMPO_TITLE',
  PLAYERS_IMPORT: 'PLAYERS_IMPORT',
  PLAYER_FIELD: 'PLAYER_FIELD',
  PLAYERS_SUBSTITUTES: 'PLAYERS_SUBSTITUTES',
  PLAYER_DELETE_CONFIRM: 'PLAYER_DELETE_CONFIRM',

  PROFILE_CONTACT: 'PROFILE_CONTACT',
  PROFILE_CLUB_ADMIN: 'PROFILE_CLUB_ADMIN',
  PROFILE_EDIT: 'PROFILE_EDIT',
  PROFILE_EARLYLABEL: 'PROFILE_EARLYLABEL',
  PROFILE_ASK_FOR_UPDATE: 'PROFILE_ASK_FOR_UPDATE',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  PROFILE_LINK_MEMBER_CTA: 'PROFILE_LINK_MEMBER_CTA',
  PROFILE_TERMS_OF_USE: 'PROFILE_TERMS_OF_USE',
  PROFILE_CGU: 'PROFILE_CGU',
  PROFILE_LINK_MEMBER_CLUB_TITLE: 'PROFILE_LINK_MEMBER_CLUB_TITLE',
  PROFILE_LINK_MEMBER_CLUB_EMPTY: 'PROFILE_LINK_MEMBER_CLUB_EMPTY',
  PROFILE_EDIT_CANCEL: 'PROFILE_EDIT_CANCEL',
  PROFILE_EDIT_VALIDATE: 'PROFILE_EDIT_VALIDATE',

  SUCCESS_DELETE: 'SUCCESS_DELETE',
  SUCCESS_ADD: 'SUCCESS_ADD',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  MODIFY: 'MODIFY',
  DELETE: 'DELETE',
  YES: 'YES',
  NO: 'NO',
  ADD: 'ADD',
  SAVE: 'SAVE',

  EARLYLABEL_TITLE: 'EARLYLABEL_TITLE',
  EARLYLABEL_TEXT: 'EARLYLABEL_TEXT',
  EARLYLABEL_LINK: 'EARLYLABEL_LINK',

  DATE_FNS_FORMAT: 'DATE_FNS_FORMAT',

  TODAY: 'TODAY',

  SPONSORS_LIVE_OFFERED_BY: 'SPONSORS_LIVE_OFFERED_BY',

  PHONE_VERIFIED: 'PHONE_VERIFIED',
  PHONE_VERIFICATION_FAILED: 'PHONE_VERIFICATION_FAILED',

  GENERAL_RANKING: 'GENERAL_RANKING',
  BEST_ATTACK_RANKING: 'BEST_ATTACK_RANKING',
  BEST_DEFENSE_RANKING: 'BEST_DEFENSE_RANKING',

  SHARE_TITLE: 'SHARE_TITLE',
  SHARE_FACEBOOK: 'SHARE_FACEBOOK',
  SHARE_TWITTER: 'SHARE_TWITTER',
  SHARE_MESSAGE_TITLE: 'SHARE_MESSAGE_TITLE',

  M_GENDER: 'M_GENDER',
  F_GENDER: 'F_GENDER',
  N_GENDER: 'N_GENDER',

  COMPETITION_AREA_1: 'COMPETITION_AREA_1',
  COMPETITION_AREA_2: 'COMPETITION_AREA_2',
  COMPETITION_AREA_3: 'COMPETITION_AREA_3',
  COMPETITION_AREA_4: 'COMPETITION_AREA_4',
  COMPETITION_AREA_5: 'COMPETITION_AREA_5',
  COMPETITION_AREA_6: 'COMPETITION_AREA_6',
  INVITE_MORE_FANS: 'INVITE_MORE_FANS',
  LIMIT_FANS_TEAM_INFORMATIONS: 'LIMIT_FANS_TEAM_INFORMATIONS',
  NO_FANS_DESCRIPTION: 'NO_FANS_DESCRIPTION',
};

export type T_KEYS = typeof T_KEYS;
