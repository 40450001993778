import dynamic from 'next/dynamic';

// Le rendu de Ionic ne fonctionne pas côté server. C'est pourquoi ssr est désactiver.

const ClientApp = dynamic(() => import('../../screens/ClientApp'), {
  ssr: false,
});

export const MobileLayoutComponent = () => {
  return <ClientApp />;
};
