import { useEffect } from 'react';

import { css, useTheme } from '@emotion/react';
import { Button, Box, Typography, Stack, useMediaQuery } from '@mui/material';
import {
  CrispService,
  SentryFallbackProps,
  useTranslation,
} from '@scorenco/core';
import { useRouter } from 'next/router';

import { T_KEYS } from '../../translations';

export const ErrorFallback = ({ error, resetError }: SentryFallbackProps) => {
  const { t } = useTranslation();
  const { back } = useRouter();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (isDesktop) {
      CrispService.show();
    }

    return () => {
      CrispService.hide();
    };
  }, []);

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        p: 'var(--safe-area-top, 0) var(--safe-area-right, 0) var(--safe-area-bottom, 0) var(--safe-area-left, 0)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box mt={4} p={2}>
        <img
          css={css`
            display: block;
            object-fit: contain;
            height: 2rem;
          `}
          src="/images/logo.png"
          alt=""
        />
      </Box>

      <Stack
        sx={{
          flex: 1,
          width: '100%',
          maxWidth: '20rem',
          px: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
          mb: '4rem',
        }}
      >
        <Typography variant="h2">{t(T_KEYS.ERROR_TITLE)}</Typography>
        <Typography
          css={css`
            font-style: italic;
          `}
        >
          {error.message}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            back();
            setTimeout(() => {
              resetError();
            }, 1000);
          }}
        >
          {t(T_KEYS.ERROR_BACK)}
        </Button>
      </Stack>
    </Stack>
  );
};
