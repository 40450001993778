import { NextPageWithLayout, ssrIsAuthenticated } from '@scorenco/next';

import { MobileLayout } from '../layouts';

const Index: NextPageWithLayout = () => {
  // WARNING: A ne pas utiliser, les pages au sens nextjs ne correspondent pas
  // au routage une fois que l'on passe en version mobile.
  //
  // Uniquement les méthodes getXXX sont à utiliser si besoin
  return <></>;
};

export const getServerSideProps = MobileLayout.buildGetServerSideProps(
  {
    i18n: {
      defaultLocale: 'fr',
    },
  },
  [
    async ({ req, res }, previous) => {
      try {
        await ssrIsAuthenticated({
          request: req,
          response: res,
        });
      } catch (e) {
        // Do nothing
        console.log('error : ', e);
      }

      return previous;
    },
  ]
);

Index.getLayout = MobileLayout.buildGetLayout();

export default Index;
